<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <!-- 第二行 -->
        <Menu name="evn-dust"
              @change="onProInfoChange($event)" />
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else
             id="content">
          <!-- 左边 -->
          <div id="left">
            <div class="leftTop">
              <div class="title">
                <h1>{{ time }}</h1>
                <div v-for="(item, key, index) in weatherData"
                     :key="index">
                  <template v-if="key == 'city'">{{ item }}天气预报</template>
                </div>
              </div>
              <div class="content">
                <div class="one"
                     v-for="(item, key, index) in weatherData"
                     :key="index">
                  <template v-if="key == 'data'">
                    <span>{{ item[0].week }}</span>
                    <!-- <span>{{item[0].week}}</span> -->
                    <img v-if="item[0].wea.includes('云')"
                         src="../../assets/image/g_duoyun.png" />
                    <img v-else-if="item[0].wea.includes('晴')"
                         src="../../assets/image/g_qing.png" />
                    <img v-else-if="item[0].wea.includes('雪')"
                         src="../../assets/image/g_xue.png" />
                    <img v-else-if="item[0].wea.includes('雨')"
                         src="../../assets/image/g_dayv.png" />
                    <img v-else-if="item[0].wea.includes('阴')"
                         src="../../assets/image/g_yin.png" />
                    <img v-else-if="item[0].wea.includes('雷')"
                         src="../../assets/image/g_lei.png" />
                    <img v-else
                         src="../../assets/image/g_wan.png" />
                    <div class="name">{{ item[0].wea }}</div>
                    <div class="wendu">
                      {{ item[0].tem2 }}~{{ item[0].tem1 }}
                    </div>
                    <div class="fengx">{{ item[0].win[0] }}</div>
                  </template>
                </div>
                <div class="two">
                  <div class="sub"
                       v-for="(item, index) in weatherData.data"
                       :key="index">
                    <template v-if="index > 0 && index < 4">
                      <span>{{ item.week }}</span>
                      <img v-if="item.wea.includes('云')"
                           src="../../assets/image/g_duoyun.png" />
                      <img v-else-if="item.wea.includes('晴')"
                           src="../../assets/image/g_qing.png" />
                      <img v-else-if="item.wea.includes('雪')"
                           src="../../assets/image/g_xue.png" />
                      <img v-else-if="item.wea.includes('雨')"
                           src="../../assets/image/g_dayv.png" />
                      <img v-else-if="item.wea.includes('阴')"
                           src="../../assets/image/g_yin.png" />
                      <img v-else-if="item.wea.includes('雷')"
                           src="../../assets/image/g_lei.png" />
                      <img v-else
                           src="../../assets/image/g_wan.png" />
                      <div class="name">{{ item.wea }}</div>
                      <div class="wendu">{{ item.tem2 }}~{{ item.tem1 }}</div>
                      <div class="fengx noml">{{ item.win[0] }}</div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="leftMain">
              <div class="yangchen">
                <h3>扬尘监测分析</h3>
                <div class="top">
                  <div class="left">
                    <PieCicleChart :id="dustEmissionCentreData.pm25LevelCount.id"
                                   :seriesData="
                        dustEmissionCentreData.pm25LevelCount.seriesData
                      "
                                   :text="dustEmissionCentreData.pm25LevelCount.text"
                                   unit="天"
                                   :showlegend="false"
                                   :showCenterData="false"
                                   height="1.59rem" />
                  </div>
                  <div class="right"
                       v-for="(item, key, index) in dustEmissionCentreData.pm25avg"
                       :key="index">
                    <div class="title">PM2.5</div>
                    <div class="day">本日平均浓度：</div>
                    <div v-if="item.day < 75"
                         class="daynum noml">
                      {{ item.day }}ug/m3
                    </div>
                    <div v-else
                         class="daynum danger">{{ item.day }}ug/m3</div>
                    <div class="month">本月平均浓度：</div>
                    <div v-if="item.month < 75"
                         class="monthnum noml">
                      {{ item.month }}ug/m3
                    </div>
                    <div v-else
                         class="monthnum danger">
                      {{ item.month }}ug/m3
                    </div>
                  </div>
                </div>
                <div class="down">
                  <div class="left">
                    <PieCicleChart :id="dustEmissionCentreData.pm10LevelCount.id"
                                   :seriesData="
                        dustEmissionCentreData.pm10LevelCount.seriesData
                      "
                                   :text="dustEmissionCentreData.pm10LevelCount.text"
                                   unit="天"
                                   :showlegend="false"
                                   height="1.59rem"
                                   :showCenterData="false" />
                  </div>
                  <div class="right"
                       v-for="(item, key, index) in dustEmissionCentreData.pm10avg"
                       :key="index">
                    <div class="title">PM10</div>
                    <div class="day">本日平均浓度：</div>
                    <div v-if="item.day < 75"
                         class="daynum noml">
                      {{ item.day }}ug/m3
                    </div>
                    <div v-else
                         class="daynum danger">{{ item.day }}ug/m3</div>
                    <div class="month">本月平均浓度：</div>
                    <div v-if="item.month < 75"
                         class="monthnum noml">
                      {{ item.month }}ug/m3
                    </div>
                    <div v-else
                         class="monthnum danger">
                      {{ item.month }}ug/m3
                    </div>
                  </div>
                </div>
              </div>
              <div class="noise">
                <h3>噪音</h3>
                <div class="title">
                  <div class="subtitle">
                    实时数据：
                    <span class="num noml">{{ dustEmissionCentreData.newData.Noise }}dB</span>
                  </div>
                  <div class="mintitle">
                    <span>最近12小时噪音</span>
                    <span>单位：db</span>
                  </div>
                </div>
                <div id="noisePic"
                     style="height: 2.04rem; transfrom: translateY(0.3rem)"
                     class="noisePic">
                  <Echart :options="noiseOptions"
                          id="bottomLeftChart"
                          width="100%"></Echart>
                </div>
              </div>
            </div>
          </div>
          <!-- 中间 -->
          <div id="center">
            <div class="main">
              <div>
                <div class="details_right1_diaogou zhgd"
                     style="">
                  <select v-model="device"
                          @change="deviceChange"
                          style="background: #3156b7;color: white;line-height: 26px;">
                    <option v-for="env in pro.Env"
                            :value="env"
                            :key="env.Id">{{env.Name}}({{env.Code}})</option>
                  </select>
                </div>
                <h1>实时环境检测</h1>
              </div>
              <div class="bgc">
                <img v-if="dustEmissionCentreData.newData.PM25 < 50"
                     src="../../assets/image/g_lvse.png"
                     class="mainImg"
                     style="width: 2.74rem; height: 2.74rem" />
                <img v-else-if="dustEmissionCentreData.newData.PM25 < 75"
                     src="../../assets/image/g_huang.png"
                     class="mainImg"
                     style="width: 2.74rem; height: 2.74rem" />
                <img v-else-if="dustEmissionCentreData.newData.PM25 < 150"
                     src="../../assets/image/g_qingdu.png"
                     class="mainImg"
                     style="width: 2.74rem; height: 2.74rem" />
                <img v-else-if="dustEmissionCentreData.newData.PM25 < 250"
                     src="../../assets/image/g_zhongdu.png"
                     class="mainImg"
                     style="width: 2.74rem; height: 2.74rem" />
                <img v-else-if="dustEmissionCentreData.newData.PM25 < 300"
                     src="../../assets/image/g_zdu.png"
                     class="mainImg"
                     style="width: 2.74rem; height: 2.74rem" />
                <img v-else
                     src="../../assets/image/g_yanzhong.png"
                     class="mainImg"
                     style="width: 2.74rem; height: 2.74rem" />

                <router-link to="/evn"
                             class="mianInfo">
                  <div v-if="device.Status == 2"
                       class="global">
                    <div class="title">AQI</div>
                    <div class="num">
                      {{ dustEmissionCentreData.newData.PM25 }}
                    </div>
                    <div class="yuan">
                      <div v-if="dustEmissionCentreData.newData.PM25 < 50"
                           class="bor"
                           style="background-color: #24e974">
                        优
                      </div>
                      <div v-else-if="dustEmissionCentreData.newData.PM25 < 75"
                           class="bor"
                           style="background-color: #feb113">
                        良
                      </div>
                      <div v-else-if="dustEmissionCentreData.newData.PM25 < 150"
                           class="bor"
                           style="background-color: #f06743">
                        轻度污染
                      </div>
                      <div v-else-if="dustEmissionCentreData.newData.PM25 < 250"
                           class="bor"
                           style="background-color: #d0021b">
                        中度污染
                      </div>
                      <div v-else-if="dustEmissionCentreData.newData.PM25 < 300"
                           class="bor"
                           style="background-color: #6c00cc">
                        重度污染
                      </div>
                      <div v-else
                           class="bor"
                           style="background-color: #6d1e06">
                        严重污染
                      </div>
                    </div>
                  </div>
                  <div v-else
                       class="global">
                    <div class="title">PM2.5</div>
                    <div class="num">离线</div>
                  </div>
                </router-link>
              </div>
              <div class="leftInfo">
                <div class="box">
                  <img src="../../assets/image/g_qiwen.png"
                       style="
                      transform: translateX(0.02rem);
                      width: 0.25rem;
                      height: 0.49rem;
                    " />
                  <div class="text"
                       style="transform: translate(0.12rem)">
                    <p style="font-weight: bold">气温</p>
                    <p class="info">
                      {{ dustEmissionCentreData.newData.Temperature }}℃
                    </p>
                  </div>
                </div>
                <div class="box">
                  <img src="../../assets/image/g_wendu.png"
                       style="width: 0.45rem; height: 0.48rem" />
                  <div class="text"
                       style="transform: translate(0.17rem)">
                    <p style="font-weight: bold">湿度</p>
                    <p class="info">
                      {{ dustEmissionCentreData.newData.Humidity }}%
                    </p>
                  </div>
                </div>
                <div class="box">
                  <img src="../../assets/image/g_fengsu.png"
                       style="width: 0.48rem; height: 0.48rem" />
                  <div class="text"
                       style="transform: translate(0.2rem)">
                    <p style="font-weight: bold">风速</p>
                    <p class="info">
                      {{ dustEmissionCentreData.newData.WindSpeed }}m/s
                    </p>
                  </div>
                </div>
              </div>
              <div class="rightInfo">
                <CenterChart2 :id="dustEmissionCentreData.pm[0].id"
                              :tips="dustEmissionCentreData.pm[0].tips"
                              :text="dustEmissionCentreData.pm[0].text"
                              :colorObj="dustEmissionCentreData.pm[0].colorData"
                              unit=""
                              :maxValue="130" />
                <CenterChart2 :id="dustEmissionCentreData.pm[1].id"
                              :tips="dustEmissionCentreData.pm[1].tips"
                              :text="dustEmissionCentreData.pm[1].text"
                              :colorObj="dustEmissionCentreData.pm[1].colorData"
                              unit=""
                              :maxValue="130" />
                <CenterChart2 :id="dustEmissionCentreData.pm[2].id"
                              :tips="dustEmissionCentreData.pm[2].tips"
                              :text="dustEmissionCentreData.pm[2].text"
                              :colorObj="dustEmissionCentreData.pm[2].colorData"
                              unit=""
                              :maxValue="80.0" />
              </div>
              <div class="bottonInfo">
                <ul>
                  <li>0 μg/m³</li>
                  <li>50 μg/m³</li>
                  <li>75 μg/m³</li>
                  <li>150 μg/m³</li>
                  <li>250 μg/m³</li>
                  <li>300 μg/m³</li>
                </ul>
                <img src="../../assets/image/g_wenran.png"
                     style="width: 5.18rem; height: 0.25rem" />
              </div>
              <div class="downtable">
                <div class="left">
                  <div class="mintitle">
                    <span>最近12小时气温</span>
                    <span>单位：℃</span>
                  </div>
                  <div id="sixwen"
                       style="height: 2.44rem; transfrom: translateY(0.4rem)"
                       class="sixwen">
                    <Echart :options="templatureOptions"
                            id="bottomLeftChart"
                            width="100%"></Echart>
                  </div>
                </div>
                <div class="right">
                  <div class="mintitle">
                    <span>最近12小时污染度</span>
                    <!--   <span>PM2.5</span>
                    <span>PM10</span>
                    <span>单位：ug</span> -->
                  </div>
                  <div id="sixwu"
                       style="height: 2.44rem; transfrom: translateY(0.4rem)"
                       class="sixwu">
                    <Echart :options="pmOptions"
                            id="pmOptions"
                            width="100%"></Echart>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 右边 -->
          <div id="right">
            <div class="up">
              <h3>用电管理</h3>
              <div class="title">
                <span>
                  <img src="../../assets/image/g_shandian.png" />
                </span>
                <span class="content">
                  电箱运行状态：
                  <span class="noml"
                        v-if="this.tableData.length">正常</span>
                  <span class="danger"
                        v-else>未安装</span>
                  <!-- <span class="danwei">&nbsp;kw/h</span> -->
                </span>
              </div>
              <div class="imgs">
                <div v-if="this.tableData.length"
                     :class="
                    this.tableData[0].doorType == 0 ? 'loudian' : 'loudian1'
                  ">
                  <div class="text">
                    <p style="font-size: 0.2rem; line-height: 0.45rem">
                      {{ this.tableData[0].doorType == 0 ? "关" : "开" }}
                    </p>
                  </div>
                </div>
                <div v-if="this.tableData.length"
                     :class="
                    this.tableData[0].envirwarm < 45 ? 'loudian' : 'loudian1'
                  ">
                  <div class="text">
                    <p>{{ this.tableData[0].envirwarm }}</p>
                    <p>℃</p>
                  </div>
                </div>
                <div v-if="this.tableData.length"
                     :class="
                    this.tableData[0].current < 150 ? 'loudian' : 'loudian1'
                  ">
                  <div class="text">
                    <p>{{ this.tableData[0].current }}</p>
                    <p>kwh</p>
                  </div>
                </div>
              </div>
              <div class="subtitle"
                   v-if="this.tableData.length">
                <p :class="this.tableData[0].doorType == 0 ? 'huang' : 'danger'">
                  箱门开关
                </p>
                <p :class="this.tableData[0].envirwarm < 45 ? 'huang' : 'danger'">
                  电箱温度
                </p>
                <p :class="this.tableData[0].current < 150 ? 'huang' : 'danger'">
                  电箱漏电
                </p>
              </div>

              <div class="door-wrap">
                <div class="table-header">
                  <div>A电箱温度</div>
                  <div>B电箱温度</div>
                  <div>C电箱温度</div>
                  <div>N电箱温度</div>
                </div>
                <div class="table-body">
                  <div class="table-rows"
                       v-for="(item, index) in tableData"
                       :key="index">
                    <div class="table-block">{{ item.awarm }}<sup>o</sup>C</div>
                    <div class="table-block">{{ item.bwarm }}<sup>o</sup>C</div>
                    <div class="table-block">{{ item.cwarm }}<sup>o</sup>C</div>
                    <div class="table-block">{{ item.nwarm }}<sup>o</sup>C</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="down">
              <h3>用水管理</h3>
              <div class="title">
                <span>
                  <img src="../../assets/image/g_shuidi.png" />
                </span>
                <span v-if="byys"
                      class="content">
                  本月用水
                  <span>{{ byys }}</span>
                  <span class="danwei">&nbsp;m³</span>
                </span>
                <span v-else
                      class="content">
                  本月用水
                  <span class="danger">未安装</span>
                </span>
              </div>
              <div class="one">
                <div class="mintitle">
                  <span>最近7天用水</span>
                  <span>单位：m³</span>
                </div>

                <div class="water">
                  <!-- <dv-water-level-pond :config="water" style="height: 1.5rem" /> -->
                </div>
                <div id="sevenworter"
                     style="width: 3.6rem; height: 1.5rem"
                     class="sevenworter"></div>
              </div>
              <div class="two">
                <div class="mintitle">
                  <span>最近6月用水</span>
                  <span>单位：m³</span>
                </div>
                <div id="sixworter"
                     style="width: 3.6rem; height: 1.5rem"
                     class="sixworter"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import ProInfoCache from "@/utils/cache/ProInfoCache";
import PieCicleChart from "@/components/echart/center/PieCicleChart";
import CenterChart2 from "@/components/echart/center/PieCicleChart2";
import Echart from "@/common/echart";
import jsonp from "jsonp";
/* import WaveEchart from "@/components/echart/center/WaveEchart"; */

moment.locale("zh-cn");
import moment from "moment";
export default {
  data () {
    return {
      loading: true,
      pro: {},
      connection: {},
      time: {},
      date: {},
      zhouji: {},
      weather: {},
      weatherInfo1: "",
      weatherInfo2: "",
      weatherInfo3: "",
      ortherWeather: [],
      byys: "",
      byyd: "",
      pm25: "",
      pm10: "",
      pid: 0, // 项目id
      deviceId: "",
      device: {},
      dustEmissionCentreData: {
        newData: {
          Status: 2,
          PM10: 0,
          PM25: 0,
          Noise: 0,
          Temperature: 0,
          Humidity: 0,
          WindSpeed: 0,
        },
        pm: [
          {
            id: "centerRate2",
            text: "PM25",
            tips: 0,
            colorData: {
              textStyle: "#3fc0fb",
              series: {
                color: ["#00bcd44a", "transparent"],
                dataColor: {
                  normal: "#03a9f4",
                  shadowColor: "#97e2f5",
                },
              },
            },
          },
          {
            id: "centerRate3",
            text: "PM10",
            tips: 0,
            colorData: {
              textStyle: "#67e0e3",
              series: {
                color: ["#faf3a378", "transparent"],
                dataColor: {
                  normal: "#ff9800",
                  shadowColor: "#fcebad",
                },
              },
            },
          },
          {
            id: "centerRate4",
            text: "噪声",
            tips: 0,
            colorData: {
              textStyle: "#67e0e3",
              series: {
                color: ["#faf3a378", "transparent"],
                dataColor: {
                  normal: "#ff9800",
                  shadowColor: "#fcebad",
                },
              },
            },
          },
        ],
        pm25LevelCount: {
          id: "centerRatePM25",
          text: "PM25",
          seriesData: [
            {
              name: "中度污染",
              value: 22,
            },
            {
              name: "轻微污染",
              value: 100,
            },
          ],
        },
        pm10LevelCount: {
          id: "centerRatePM10",
          text: "PM10",
          seriesData: [
            {
              name: "中度污染",
              value: 22,
            },
            {
              name: "轻微污染",
              value: 100,
            },
          ],
        },
        pm25avg: [
          {
            day: 0,
            month: 0,
          },
        ],
        pm10avg: [
          {
            day: 0,
            month: 0,
          },
        ],
      }, // 扬尘监测器数据
      weatherData: {
        key: "city",
        data: [
          {
            week: "周一",
            wea: "小雨",
            tem1: "1",
            tem2: "2",
            win: ["北风"],
          },
          {
            week: "周二",
            wea: "阴",
            tem1: "1",
            tem2: "2",
            win: ["西北风"],
          },
          {
            week: "周三",
            wea: "多云转晴",
            tem1: "1",
            tem2: "2",
            win: ["东北风"],
          },
          {
            week: "周四",
            wea: "晴",
            tem1: "1",
            tem2: "2",
            win: ["无风"],
          },
        ],
      }, // 天气数据
      noiseOptions: {},
      noiseOptionsCdata: {
        category: [],
        data: [],
      },
      templatureOptions: {},
      templatureOptionsCdata: { category: [], data: [] },
      pmOptions: {},
      pmOptionsCdata: { category: [], pm10Data: [], pm25Data: [] },
      water: {
        data: [24, 45, 30, 38, 42],
        shape: "roundRect",
        formatter: "{value}%",
        waveNum: 3,
      },
      dustPicMax: 60, // 污染度最大值
      noiseMax: 100, // 噪音最大值
      electricBoxData: "", // 电箱数据
      tableData: [], // 电箱表格
    };
  },
  components: {
    Menu,
    PieCicleChart,
    CenterChart2,
    Echart
    /* WaveEchart, */
  },
  created: function () {
    let connection = this.$op.getConnect(this);
    connection.on("evn_attence", (x) => {
      console.log(x);
      //   if (x.DeviceStatus == 2) {
      //     x.pM25 = x.DeviceStatusTypeText;
      //   }
      this.dustEmissionCentreData.newData = {
        Status: x.deviceStatus,
        PM10: x.pM10,
        PM25: x.pM25,
        Noise: x.noise,
        Temperature: x.temperature,
        Humidity: x.humidity,
        WindSpeed: x.windSpeed,
      };
      this.dustEmissionCentreData.pm[0].tips = x.pM25;
      this.dustEmissionCentreData.pm[1].tips = x.pM10;
      this.dustEmissionCentreData.pm[2].tips = x.noise;
    });
  },
  mounted () {
    this.pro = ProInfoCache.getProInfo();
    this.initForm();
  },
  watch: {
    noiseOptionsCdata: {
      handler (newData) {
        this.noiseOptions = {
          grid: {
            left: "2%",
            right: "2%",
            bottom: "10%",
            containLabel: false,
          },
          tooltip: {
            formatter: "{b}\n噪声:{c}db",
          },
          calculable: true,
          xAxis: {
            type: "category",
            boundaryGap: true,
            data: newData.category,
            interval: 0,
            axisTick: {
              show: true,
              inside: true,
              lineStyle: {
                color: "#10899A",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#06D3CD",
              },
            },
            axisLabel: {
              rotate: 8,
              color: "#06D3CD",
              /*  fontFamily: "PingFangSC", */
              fontSize: 14,
              fontWeight: 300,
            },
          },
          yAxis: {
            type: "value",
            show: false,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#06D3CD",
              },
            },
            axisTick: {
              lineStyle: {
                color: "#10899A",
              },
            },
            axisLabel: {
              showMaxLabel: false,
              color: "#06D3CD",
              fontFamily: "PingFangSC",
              fontSize: 14,
              fontWeight: 300,
            },
          },
          series: [
            {
              data: newData.data,
              type: "line",
              smooth: true,
              symbol: "circle",
              areaStyle: {
                normal: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#dc3881", // 0% 处的颜色
                      },
                      {
                        offset: 0.7,
                        color: "rgba(220,56,129,0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
    templatureOptionsCdata: {
      handler (newData) {
        this.templatureOptions = {
          grid: {
            left: "2%",
            right: "2%",
            bottom: "10%",
            containLabel: true,
          },
          tooltip: {
            formatter: "{b}\n 温度:{c}℃",
          },
          calculable: true,
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: newData.category,
            interval: 0,
            axisTick: {
              show: false,
              inside: true,
              lineStyle: {
                color: "#10899A",
              },
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#06D3CD",
              },
            },
            axisLabel: {
              rotate: 8,
              color: "#06D3CD",
              fontSize: 14,
              fontWeight: 300,
            },
          },
          yAxis: {
            type: "value",
            show: false,
            splitLine: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#06D3CD",
              },
            },
            axisTick: {
              lineStyle: {
                color: "#10899A",
              },
            },
            axisLabel: {
              showMaxLabel: false,
              color: "#06D3CD",
              fontFamily: "PingFangSC",
              fontSize: 14,
              fontWeight: 300,
            },
          },
          series: [
            {
              data: newData.data,
              type: "line",
              smooth: true,
              symbol: "circle",
              areaStyle: {
                normal: {
                  color: {
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#dc3881", // 0% 处的颜色
                      },
                      {
                        offset: 0.7,
                        color: "rgba(220,56,129,0)", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
    pmOptionsCdata: {
      handler (newData) {
        this.pmOptions = {
          title: {
            text: "",
            subtext: "",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["PM2.5", "PM10"],
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none",
              },
              dataView: { readOnly: false },
              magicType: { type: ["line", "bar"] },
              restore: {},
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: newData.category,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          series: [
            {
              name: "PM2.5",
              type: "line",
              data: newData.pm25Data,
              /*   markPoint: {
                data: [
                  { type: "max", name: "最大值" },
                  { type: "min", name: "最小值" },
                ],
              },
              markLine: {
                data: [{ type: "average", name: "平均值" }],
              }, */
            },
            {
              name: "PM10",
              type: "line",
              data: newData.pm10Data,
              markPoint: {
                data: [{ name: "周最低", value: 2, xAxis: 1, yAxis: 1.5 }],
              },
              markLine: {
                data: [
                  { type: "average", name: "平均值" },
                  [
                    {
                      symbol: "none",
                      x: "90%",
                      yAxis: "max",
                    },
                    {
                      symbol: "circle",
                      label: {
                        normal: {
                          position: "start",
                          formatter: "最大值",
                        },
                      },
                      type: "max",
                      name: "最高点",
                    },
                  ],
                ],
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onProInfoChange (e) {
      this.pro = e;
      //this.pro = ProInfoCache.getProInfo()
      this.initForm()
    },
    initForm () {
      this.device = []
      if (this.pro.Env && this.pro.Env.length > 0) {
        this.device = this.pro.Env[0]
        this.deviceId = this.device.Id
        this.bindDevice()
      }
      this.getTime();
      this.loadWether();
      this.loadDataInit();
      this.changeTiming();
      this.cancelLoading();
    },
    deviceChange () {
      this.bindDevice()
      this.loadDataInit()
    },
    bindDevice () {
      this.dustEmissionCentreData = {
        newData: {
          Status: 2,
          PM10: 0,
          PM25: 0,
          Noise: 0,
          Temperature: 0,
          Humidity: 0,
          WindSpeed: 0,
        },
        pm: [
          {
            id: "centerRate2",
            text: "PM25",
            tips: 0,
            colorData: {
              textStyle: "#3fc0fb",
              series: {
                color: ["#00bcd44a", "transparent"],
                dataColor: {
                  normal: "#03a9f4",
                  shadowColor: "#97e2f5",
                },
              },
            },
          },
          {
            id: "centerRate3",
            text: "PM10",
            tips: 0,
            colorData: {
              textStyle: "#67e0e3",
              series: {
                color: ["#faf3a378", "transparent"],
                dataColor: {
                  normal: "#ff9800",
                  shadowColor: "#fcebad",
                },
              },
            },
          },
          {
            id: "centerRate4",
            text: "噪声",
            tips: 0,
            colorData: {
              textStyle: "#67e0e3",
              series: {
                color: ["#faf3a378", "transparent"],
                dataColor: {
                  normal: "#ff9800",
                  shadowColor: "#fcebad",
                },
              },
            },
          },
        ],
        pm25LevelCount: {
          id: "centerRatePM25",
          text: "PM25",
          seriesData: [
            {
              name: "中度污染",
              value: 22,
            },
            {
              name: "轻微污染",
              value: 100,
            },
          ],
        },
        pm10LevelCount: {
          id: "centerRatePM10",
          text: "PM10",
          seriesData: [
            {
              name: "中度污染",
              value: 22,
            },
            {
              name: "轻微污染",
              value: 100,
            },
          ],
        },
        pm25avg: [
          {
            day: 0,
            month: 0,
          },
        ],
        pm10avg: [
          {
            day: 0,
            month: 0,
          },
        ],
      }
      if (this.device)
        this.dustEmissionCentreData.newData = {
          Status: this.device.Status,
          PM10: this.device.PM10,
          PM25: this.device.PM25,
          Noise: this.device.Noise,
          Temperature: this.device.Temperature,
          Humidity: this.device.Humidity,
          WindSpeed: this.device.WindSpeed,
        }
    },
    getTime () {
      this.time = moment().format("HH:mm");
      this.date = moment().format("MMM Do");
      this.zhouji = moment().format("ddd");
      setInterval(() => {
        this.time = moment().format("HH:mm");
      }, 60000);
    },
    // 根据自己的业务情况修改
    changeTiming () {
      setInterval(() => {
        this.loadDataInit();
        this.loadWether();
      }, 1000 * 10 * 60);
    },
    loadData () {
      this.$http
        .post("/DeviceManage/DeviceEnv/GetEnvAnalysis", {
          DeviceId: this.device.Id,
        })
        .then((resJson) => {
          this.dustEmissionCentreData.pm25avg = [];
          this.dustEmissionCentreData.pm25avg.push({
            day: resJson.Data.PM25Day,
            month: resJson.Data.PM25Month,
          });
          this.dustEmissionCentreData.pm10avg = [];
          this.dustEmissionCentreData.pm10avg.push({
            day: resJson.Data.PM10Day,
            month: resJson.Data.PM10Month,
          });
          this.dustEmissionCentreData.pm25LevelCount.seriesData = [];
          this.dustEmissionCentreData.pm10LevelCount.seriesData = [];
          if (resJson.Data.PM25 && resJson.Data.PM25.length > 0) {
            for (let index = 0; index < resJson.Data.PM25.length; index++) {
              const element = resJson.Data.PM25[index];
              this.dustEmissionCentreData.pm25LevelCount.seriesData.push({
                name: element.Key,
                value: element.Count,
              });
            }
          }
          if (resJson.Data.PM10 && resJson.Data.PM10.length > 0) {
            for (let index = 0; index < resJson.Data.PM10.length; index++) {
              const element = resJson.Data.PM10[index];
              this.dustEmissionCentreData.pm10LevelCount.seriesData.push({
                name: element.Key,
                value: element.Count,
              });
            }
          }
          this.noiseOptionsCdata.category = [];
          this.noiseOptionsCdata.data = [];
          if (resJson.Data.Noise && resJson.Data.Noise.length > 0) {
            for (let index = 0; index < resJson.Data.Noise.length; index++) {
              const element = resJson.Data.Noise[index];
              this.noiseOptionsCdata.category.push(element.Key);
              this.noiseOptionsCdata.data.push(element.Value1);
            }
          }
          this.templatureOptionsCdata.category = [];
          this.templatureOptionsCdata.data = [];
          if (resJson.Data.Templature && resJson.Data.Templature.length > 0) {
            for (let index = 0; index < resJson.Data.Templature.length; index++) {
              const element = resJson.Data.Templature[index];
              this.templatureOptionsCdata.category.push(element.Key);
              this.templatureOptionsCdata.data.push(element.Value1);
            }
          }
          this.pmOptionsCdata.category = [];
          this.pmOptionsCdata.pm25Data = [];
          this.pmOptionsCdata.pm10Data = [];
          if (resJson.Data.PM && resJson.Data.PM.length > 0) {
            for (let index = 0; index < resJson.Data.PM.length; index++) {
              const element = resJson.Data.PM[index];
              this.pmOptionsCdata.category.push(element.Key);
              this.pmOptionsCdata.pm25Data.push(element.Value1);
              this.pmOptionsCdata.pm10Data.push(element.Value2);
            }
          }
        });
    },
    loadDataInit () {
      if (!this.pro || !this.pro.Env)
        return
      this.loadData();
      this.$http
        .post("/DeviceManage/DeviceEnv/GetEnvLastRecord", {
          DeviceId: this.device.Id,
          ProId: this.device.ProId,
          Code: this.device.Code
        })
        .then((resJson) => {
          let x = resJson.Data;
          /* if (x.DeviceStatus == 1) {
            x.PM25 = x.DeviceStatusTypeText;
          } */
          this.dustEmissionCentreData.newData = {
            Status: x.DeviceStatus,
            PM10: x.PM10,
            PM25: x.PM25,
            Noise: x.Noise,
            Temperature: x.Temperature,
            Humidity: x.Humidity,
            WindSpeed: x.WindSpeed,
          };
          this.dustEmissionCentreData.pm[0].tips = x.PM25;
          this.dustEmissionCentreData.pm[1].tips = x.PM10;
          this.dustEmissionCentreData.pm[2].tips = x.Noise;
        });
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    loadWether () {
      console.log("123")
      jsonp('//api.map.baidu.com/telematics/v3/weather?location=北京&output=json&ak=5slgyqGDENN7Sy7pw29IUvrZ', null, (err, data) => {
        if (err) {
          console.error(err.message);
        } else {
          console.log(data);
        }
      });
    }
  },
};
</script>

<style lang="scss">
.danger {
  color: #fb497c !important;
}
.noml {
  color: #24e974 !important;
}
.huang {
  color: #ffb70e !important;
}
#content {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.3rem 0 0.3rem;
  height: 10.3rem;
  overflow: hidden;
  // background-image: url('./2.5.png');
  h3 {
    font-weight: 900;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(249 242 242 / 85%);
  }
  #left {
    width: 22.22%;
    height: 100%;
    .leftTop {
      width: 4.14rem;
      height: 1.96rem;
      background: url("../../assets/image/g_zuoshang.png") no-repeat center
        center;
      background-size: 100% 100%;
      padding: 0.2rem;
      padding: 0.15rem 0.2rem 0.18rem 0.11rem;
      .title {
        display: flex;
        color: #fff;
        justify-content: space-between;
        h1 {
          font-size: 0.3rem;
          margin-left: 0.14rem;
        }
        div {
          margin-right: 0.5rem;
          font-weight: 700;
        }
      }
      .content {
        color: #fff;
        margin-top: 0.05rem;
        .one {
          text-align: center;
          font-size: 0.12rem;
          position: relative;
          width: 1rem;
          float: left;
          &::after {
            content: "";
            height: 1.35rem;
            width: 0.01rem;
            background-color: #fff;
            position: absolute;
            top: 0.05rem;
            right: -0.2rem;
          }
          span {
            font-size: 0.12rem;
            margin-left: 0.1rem;
          }
          img {
            margin-left: 0.25rem;
            width: 0.54rem;
            height: 0.54rem;
            display: block;
            padding: 0.05rem;
          }
          .wendu {
            margin: 0;
            transform: translateY(-0.05rem);
          }
          .fengx {
            margin-top: -0.05rem;
            margin-left: 0.2rem;
            background-color: #3ee19a;
            width: 0.7rem;
            height: 0.2rem;
            border-radius: 0.05rem;
            text-align: center;
            line-height: 0.2rem;
            overflow: hidden;
          }
        }
        .two {
          text-align: center;
          transform: translateX(0.14rem);
          display: flex;
          margin-left: 0.6rem;
          font-size: 0.12rem;
          .sub {
            width: 0.8rem;
            margin-left: 0.1rem;
            img {
              margin-left: 0.13rem;
              width: 0.54rem;
              height: 0.54rem;
              display: block;
              padding: 0.05rem;
            }
            .wendu {
              margin: 0;
              transform: translateY(-0.05rem);
            }
            .fengx {
              margin-top: -0.05rem;
              margin-left: 0.15rem;
              width: 0.5rem;
              height: 0.2rem;
              border-radius: 0.05rem;
              text-align: center;
              line-height: 0.2rem;
              overflow: hidden;
            }
          }
        }
      }
    }
    .leftMain {
      width: 4.14rem;
      height: 7.25rem;
      background: url("../../assets/image/g_zuoxia.png") no-repeat center center;
      background-size: 100% 100%;
      margin-top: 0.3rem;
      .yangchen {
        .top,
        .down {
          position: relative;
          padding: 0 0.42rem;
          display: flex;
          justify-content: space-between;
          .left {
            position: relative;
            width: 50%;
            div {
              position: absolute;
              left: -19%;
              top: 52%;
              transform: translateY(-50%);
              color: #cbccce;
              text-align: center;
              margin-left: 12px;
              .num {
                font-size: 0.18rem;
                color: #fff;
              }
            }
          }
          .right {
            color: #fff;
            font-size: 0.16rem;
            .title {
              width: 50% !important;
              font-size: 0.18rem;
            }
            .day {
              margin-top: 0.04rem;
            }
            .month {
              margin-top: 0.04rem;
            }
          }
        }
        .top {
          margin-top: 0.2rem;
        }
        .down {
          margin-top: 0.4rem;
        }
      }
      .noisePic {
        transform: translateY(0.65rem);
      }
      .noise {
        position: relative;
        margin-top: 0.35rem;
        .title {
          color: #fff;
          .subtitle {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-size: 0.18rem;
          }
          .mintitle {
            position: absolute;
            margin-top: 0.4rem;
            span {
              &:nth-child(1) {
                float: left;
                margin-left: 0.2rem;
                font-size: 0.14rem;
              }
              &:nth-child(2) {
                float: right;
                margin-left: 2.1rem;
                font-size: 0.12rem;
                &::before {
                  position: absolute;
                  content: "";
                  width: 0.05rem;
                  height: 0.05rem;
                  border-radius: 50%;
                  background-color: #fff;
                  right: 0.55rem;
                  top: 0.1rem;
                }
              }
            }
          }
        }
      }
    }
  }

  #center {
    flex: 1;
    height: 100%;
    margin-left: 1.4%;
    .main {
      width: 100%;
      height: 9.51rem;
      background: url("../../assets/image/g_zhong.png") no-repeat center center;
      background-size: 100% 100%;
      position: relative;
      color: #fff;
      padding: 0.34rem 0.4rem 0.4rem 0.45rem;
      h1 {
        position: absolute;
        top: 0.34rem;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 900;
      }
      .bgc {
        width: 4rem;
        height: 4rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0.9rem;
        background: url("../../assets/image/g_hc.gif") no-repeat center center;
        background-size: 100% 100%;
        .mainImg {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0.66rem;
          // border-radius: 50%;
        }
      }
      .mianInfo {
        width: 2.74rem;
        height: 2.74rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 1.6rem;
        border-radius: 50%;
        .global {
          text-align: center;
          // display: flex;
          // flex-direction: column;
          .title {
            color: #b8b8bc;
            font-size: 0.32rem;
            margin-top: 0.55rem;
            width: auto !important;
            font-weight: 700;
          }
          .num {
            color: #fff;
            font-weight: 900;
            font-size: 0.45rem;
            margin-top: 0.41rem;
          }
          .yuan {
            width: 1.24rem;
            height: 0.4rem;
            border: 0.02rem solid #656d6a;
            border-radius: 0.2rem;
            margin: 0 auto;
            transform: translateY(0.4rem);
            padding: 0.03rem;
            .bor {
              color: #fff;
              width: 100%;
              height: 100%;
              background-color: #24e974;
              border-radius: 0.2rem;
              text-align: center;
              line-height: 0.3rem;
              font-size: 0.18rem;
              font-weight: 600;
            }
          }
        }
      }
      .leftInfo {
        position: absolute;
        right: 0.64rem;
        top: 1.4rem;
        width: 1.6rem;
        .box {
          margin-top: 0.4rem;
          display: flex;
          justify-content: space-between;
          // text-align: center;
          img {
            transform: translateX(-0.1rem);
          }
          .text {
            font-size: 0.22rem;
            text-align: left;
            margin-left: 0.02rem;
            width: 0.8rem;
            .info {
              text-align: left;
              transform: translateY(0.06rem);
              font-size: 0.2rem;
            }
          }
        }
      }
      .rightInfo {
        position: absolute;
        bottom: 3rem;
        left: 0.3rem;
      }
      .bottonInfo {
        position: absolute;
        bottom: 3.8rem;
        left: 50%;
        transform: translateX(-50%);
        ul {
          display: flex;
          justify-content: space-between;
          font-size: 0.12rem;
          li {
            width: 0.8633rem;
          }
        }
      }
      .downtable {
        position: absolute;
        bottom: 0.75rem;
        width: 100%;
        display: flex;
        .left {
          flex: 1;
          .mintitle {
            position: absolute;
            margin-top: 0.4rem;
            transform: translateX(-0.2rem);
            span {
              &:nth-child(1) {
                float: left;
                margin-left: 0.2rem;
                font-size: 0.14rem;
                transform: translateY(-0.2rem);
              }
              &:nth-child(2) {
                float: right;
                margin-left: 2.1rem;
                font-size: 0.12rem;
                &::before {
                  position: absolute;
                  content: "";
                  width: 0.05rem;
                  height: 0.05rem;
                  border-radius: 50%;
                  background-color: #569acc;
                  right: 0.55rem;
                  top: 0.1rem;
                }
              }
            }
          }
        }
        .right {
          flex: 1;
          .mintitle {
            position: absolute;
            margin-top: 0.4rem;
            transform: translateX(-0.2rem);
            span {
              &:nth-child(1) {
                float: left;
                margin-left: 0.2rem;
                font-size: 0.14rem;
                transform: translateY(-0.2rem);
              }
              &:nth-child(2) {
                font-size: 0.12rem;
                margin-left: 1.2rem;
                &::before {
                  position: absolute;
                  content: "";
                  width: 0.05rem;
                  height: 0.05rem;
                  border-radius: 50%;
                  background-color: #569acc;
                  right: 1.85rem;
                  top: 0.1rem;
                }
              }
              &:nth-child(3) {
                font-size: 0.12rem;
                margin-left: 0.3rem;
                &::before {
                  position: absolute;
                  content: "";
                  width: 0.05rem;
                  height: 0.05rem;
                  border-radius: 50%;
                  background-color: #24e974;
                  right: 1.18rem;
                  top: 0.1rem;
                }
              }
              &:nth-child(4) {
                float: right;
                margin-left: 0.3rem;
                font-size: 0.12rem;
              }
            }
          }
        }
        .sixwen,
        .sixwu {
          transform: translate(-0.45rem, 0.65rem);
        }
      }
    }
  }

  #right {
    width: 22.22%;
    height: 100%;
    margin-left: 1.4%;
    .up {
      display: block;
      width: 4.14rem;
      height: 4.61rem;
      background: url("../../assets/image/g_youshang.png") no-repeat center
        center;
      background-size: 100% 100%;
      .title {
        width: 3.72rem;
        height: 0.46rem;
        background-color: #071038;
        margin: 0 auto;
        color: #fff;
        line-height: 0.46rem;
        font-size: 0.16rem;
        padding-left: 0.34rem;
        .content {
          margin-left: 0.1rem;
          .danwei {
            font-size: 0.12rem;
          }
        }
      }
      .imgs {
        display: flex;
        justify-content: space-around;
        margin-top: 0.2rem;
        padding: 0 0.35rem;
        div {
          width: 0.84rem;
          height: 0.84rem;
          .text {
            color: #ffb70e;
            text-align: center;
            margin-top: 0.2rem;
            p {
              &:nth-child(1) {
                font-size: 0.18rem;
              }
              &:nth-child(2) {
                margin-top: -0.05rem;
                font-size: 0.12rem;
              }
            }
          }
        }
        .daydian {
          background: url("../../assets/image/g_ydhuang.png") no-repeat center
            center;
          background-size: 100% 100%;
        }
        .dianxiang {
          background: url("../../assets/image/g_ydhuang.png") no-repeat center
            center;
          background-size: 100% 100%;
        }
        .loudian {
          background: url("../../assets/image/g_ydhuang.png") no-repeat center
            center;
          background-size: 100% 100%;
          .text {
            color: #ffb70e;
          }
        }
        .loudian1 {
          background: url("../../assets/image/g_ydhong.png") no-repeat center
            center;
          background-size: 100% 100%;
          .text {
            color: #fb497c;
          }
        }
      }
      .subtitle {
        display: flex;
        justify-content: space-around;
        text-align: center;
        padding: 0 0.35rem;
        margin-top: 0.1rem;
        p {
          width: 0.84rem;
        }
      }
      .door-state {
        width: 3.44rem;
        color: #fff;
        margin: 0 auto;
        margin-top: 0.2rem;
        .door-title {
          font-size: 0.14rem;
          line-height: 0.29rem;
        }
        .door-data {
          height: 1.4rem;
          overflow: hidden;
          margin-top: 0.1rem;
          ul {
            li {
              display: flex;
              justify-content: space-between;
              line-height: 0.35rem;
            }
          }
        }
      }
      .door-wrap {
        width: 100%;
        color: #fff;
        padding-top: 0.2rem;
        height: 2.1rem;
        overflow: hidden;
        .table-header {
          display: flex;
          justify-content: space-between;
          padding: 0 0.35rem;
        }
        .table-rows {
          display: flex;
          justify-content: space-between;
          padding: 0 0.35rem;
        }
      }
    }
    .down {
      margin-top: 0.3rem;
      width: 4.14rem;
      height: 4.6rem;
      background: url("../../assets/image/g_youxia.png") no-repeat center center;
      background-size: 100% 100%;
      .title {
        width: 3.72rem;
        height: 0.46rem;
        background-color: #071038;
        margin: 0 auto;
        color: #fff;
        line-height: 0.46rem;
        font-size: 0.16rem;
        padding-left: 0.34rem;
        .content {
          margin-left: 0.1rem;
          .danwei {
            font-size: 0.12rem;
          }
        }
      }
      .one {
        color: #fff;
        .mintitle {
          position: absolute;
          margin-top: 0.3rem;
          transform: translateX(-0.2rem);
          color: #fff;
          span {
            &:nth-child(1) {
              float: left;
              margin-left: 0.5rem;
              font-size: 0.14rem;
              transform: translateY(-0.2rem);
            }
            &:nth-child(2) {
              float: right;
              transform: translateY(-0.05rem);
              margin-left: 2.1rem;
              font-size: 0.12rem;
              &::before {
                position: absolute;
                content: "";
                width: 0.06rem;
                height: 0.06rem;
                border-radius: 50%;
                background-color: #fff;
                right: 0.68rem;
                top: 0.1rem;
              }
            }
          }
        }
        .sevenworter {
          transform: translate(0.15rem, 0.35rem);
        }
      }
      .two {
        color: #fff;
        .mintitle {
          position: absolute;
          margin-top: 0.5rem;
          transform: translateX(-0.2rem);
          color: #fff;
          span {
            &:nth-child(1) {
              float: left;
              margin-left: 0.5rem;
              font-size: 0.14rem;
              transform: translateY(-0.2rem);
            }
            &:nth-child(2) {
              float: right;
              transform: translateY(-0.05rem);
              margin-left: 2.1rem;
              font-size: 0.12rem;
              &::before {
                position: absolute;
                content: "";
                width: 0.06rem;
                height: 0.06rem;
                border-radius: 50%;
                background-color: #fff;
                right: 0.68rem;
                top: 0.1rem;
              }
            }
          }
        }
        .sixworter {
          transform: translate(0.15rem, 0.6rem);
        }
      }
    }
  }
}
</style>
